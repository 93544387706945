import * as React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import Script from 'next/script'
import getConfig from 'next/config'
import SbEditable from 'storyblok-react'
import StoryblokService from 'utils/storyblok-service'
import { useStoryblok, parsePageData } from 'utils/storyblok'
import * as blockVariants from 'blocks'
import MetaTags from 'containers/MetaTags'

export const Page = (props) => {
  const { page } = props

  const content = useStoryblok(page)

  return (
    <>
      <Head>
        <title>{`${page?.seo?.title || content?.title || ''} | KÄLLA`}</title>
        <Script src={StoryblokService.bridge()} strategy={'beforeInteractive'} />
      </Head>
      <MetaTags page={page} />

      {content?.body?.map((block, idx) => {
        const { component, _uid: uid, ...blockProps } = block
        const Block = blockVariants[component] || <div>No block</div>

        return (
          <SbEditable key={uid} content={block}>
            <Block {...blockProps} renderIndex={idx} />
          </SbEditable>
        )
      })}
    </>
  )
}

Page.propTypes = {
  page: PropTypes.object,
  tinyLink: PropTypes.object,
}

export async function getServerSideProps(ctx) {
  const { params, preview, query } = ctx
  const uri = [...(params?.uri || [])]
  const uriAsString = uri.join('/')
  const slug = uriAsString.length ? uriAsString : 'home'

  if (query) StoryblokService.setQuery(query)
  if (preview) StoryblokService.devMode = true

  const relations = ['ArticleArchive.featured', 'ArticleGroup.items']

  try {
    const { publicRuntimeConfig } = getConfig()

    const pageData = await StoryblokService.get(`cdn/stories/${slug}?language=${ctx.locale}`, {
      resolve_relations: relations.join(','),
      version: publicRuntimeConfig.STORYBLOK_ENV,
    })

    const page = await parsePageData(pageData, ctx)

    return {
      props: {
        page,
      },
    }
  } catch (error) {
    return {
      notFound: true,
    }
  }
}

export default Page
