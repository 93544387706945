import * as React from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useGlobal } from 'api'

const MetaTags = (props) => {
  const { page } = props

  const { publicRuntimeConfig } = getConfig()

  const { products, currencyData, language, menus } = useGlobal()
  const router = useRouter()

  const [metaProductLD, setMetaProductLD] = React.useState(null)
  const [breadcrumbsLD, setBreadcrumbsLD] = React.useState(null)

  React.useEffect(() => {
    if (window && products?.length) {
      const product = products?.find((p) => p.productCode === page?.seo?.productCode.trim())

      if (product) {
        const productLD = {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: product?.name,
          image:
            product?.imagesData?.filter((image) => image?.tag === 'Hero_R')?.[0]?.imageUrl ||
            product?.imageUrl,
          description: product?.custom5 || product?.custom4,
          sku: page?.seo?.productCode,
          productID: `${
            page?.seo?.productGroup ? `${page?.seo?.productGroup}_` : ''
          }${product?.slugUri.replaceAll('-', '_')}`,
          offers: {
            '@type': 'AggregateOffer',
            priceCurrency: currencyData?.isoCodeAlpha,
            lowPrice: product?.priceFrom || 0,
            highPrice: product?.priceTo || 0,
            offerCount: product?.prices?.length,
            url: `${publicRuntimeConfig.TINYLINK_PREFIX}${
              router.locale !== 'redirect' ? `/${router.locale}` : ''
            }${product?.href}`,
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'https://schema.org/InStock',
          },
          brand: {
            '@type': 'Brand',
            name: 'KÄLLA',
          },
          additionalProperty: [
            {
              '@type': 'PropertyValue',
              propertyID: 'item_group_id',
              value: page?.seo?.productGroup || 'kalla',
            },
          ],
        }

        setMetaProductLD(productLD)
      }
    }
  }, [
    currencyData?.isoCodeAlpha,
    language.locale,
    page?.seo.description,
    page?.seo.image.filename,
    page?.seo?.productCode,
    page?.seo?.productGroup,
    page?.seo?.title,
    products,
    publicRuntimeConfig.TINYLINK_PREFIX,
    router.locale,
  ])

  React.useEffect(() => {
    if (window && router.asPath !== '/') {
      const breadcrumbs = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: page?.data?.story?.name || page?.seo?.title,
            item: `${publicRuntimeConfig.TINYLINK_PREFIX}${
              router.locale !== 'redirect' ? `/${router.locale}` : ''
            }${router.asPath}`,
          },
        ],
      }

      setBreadcrumbsLD(breadcrumbs)
    }
  }, [
    page?.data?.story?.name,
    page?.seo?.title,
    publicRuntimeConfig.TINYLINK_PREFIX,
    router.asPath,
    router.locale,
  ])

  const webLD = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'KÄLLA',
    url: `${publicRuntimeConfig.TINYLINK_PREFIX || ''}`,
    logo: page?.seo?.image?.filename || 'https://kalla.com/share.png',
    description: page?.seo?.description || '',
    keywords: page?.seo?.keywords || '',
    sameAs: [...menus?.footer?.[2]?.links?.map((menu) => menu?.url)],
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Stockholm',
      addressCountry: 'Sweden',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      telephone: '+460791046080',
      email: 'support@kalla.com',
    },
  }

  return (
    <Head>
      <meta name="description" content={page?.seo?.description} />
      <meta name="keywords" content={page?.seo?.keywords} />
      <meta property="og:title" content={page?.seo?.title} />
      <meta property="og:description" content={page?.seo?.description} />
      <meta
        property="og:image"
        content={page?.seo?.image?.filename || 'https://kalla.com/share.png'}
      />
      <meta
        property="og:url"
        content={`${publicRuntimeConfig.TINYLINK_PREFIX}${
          router.locale !== 'redirect' ? `/${router.locale}` : ''
        }${router.asPath}`}
      />
      <meta property="og:site_name" content={'KÄLLA'} />
      <meta property="og:type" content={metaProductLD ? 'product' : 'website'} />
      <meta property="og:locale" content={language?.locale} />
      <script type="application/ld+json">{JSON.stringify(webLD)}</script>
      {metaProductLD && <script type="application/ld+json">{JSON.stringify(metaProductLD)}</script>}
      {breadcrumbsLD && <script type="application/ld+json">{JSON.stringify(breadcrumbsLD)}</script>}
    </Head>
  )
}

MetaTags.propTypes = {
  page: PropTypes.object.isRequired,
}

export default MetaTags
